import React from 'react'
import axios from 'axios'
import { message } from 'antd'
import { getToken } from './index'
import store from './../store'
import { logout } from './../store/actions'
import qs from 'query-string'
// import { courseURL } from './index'

const instance = axios.create({
  // baseURL: 'https://learn.dev.longan.eliteu.xyz'
})

instance.interceptors.request.use(
  (request) => {
    const token = getToken()
    if (token) {
      request.headers.Authorization = `Bearer ${token}`
    }
    // console.log('request',request);
    return request
  },
  (error) => {
    message.error(error.message)
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (process.env.NODE_ENV === 'development') {
      message.error('开发服提示：' + error.message)
    }
    if (!error?.response?.status || error?.response?.status === 404) {
      // 特殊场景业务处理
      // TA 的页面，没权限时返回 xxx 显示 「无访问权限」
      if (error.response.config.url.includes('/users/')) {
        window.location.href = '/403'
        return Promise.reject(error)
      }
      if (error.response.config.url.includes('/api/v1/org_studio/') && error.response.config.method === 'get') {
        if (/^\/api\/v1\/org_studio\/\d+\/$/.test(error.response.config.url)) {
          message.warning('该作品集已被创建者删除', 3)
          setTimeout(() => {
            window.history.back()
          }, 3000);
        }
        return Promise.reject(error)
      } else {
        window.location.href = '/404'
      }

    }

    // 如果有数据但是没有权限访问，那么是403
    if (!error?.response?.status || error?.response?.status === 403) {
      window.location.href = '/403'
    }

    if (!error?.response?.status && error?.response?.status === 401) {
      store.dispatch(logout())
      // Toast.info('需要登录哦')
    }
    return Promise.reject(error)
  },
)

export const checkContent = (data) => {
  message.destroy()
  let string = ''
  if (typeof data === 'string') {
    string = data
  } else if (data instanceof FormData) {
    for (let value of data.values()) {
      if (typeof value === 'string' && !value.includes('.png')) {
        string += value
      }
    }
  } else if (typeof data === 'object') {
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'string' && !data[key].includes('.png')) {
        string += data[key]
      } else if (typeof data[key] === 'object') {
        string += JSON.stringify(data[key])
      }
    })
  } else {
    console.error('敏感词检测数据格式 error', data)
  }

  return new Promise((resolve, reject) => {
    instance
      .post('/check', { content: string })
      .then((res) => {
        if (!res.data.pass) {
          message.error({
            content: (
              <span>
                很抱歉，我们检测到你的这段文字中包含一些不符合文明礼仪
                {/* <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.longan.link/%E9%BE%99%E7%9C%BC%E7%A4%BE%E5%8C%BA%E5%85%AC%E7%BA%A6/"
                >
                  《Longan 社区公约》
                </a> */}
                的内容，
                <br />
                请检查并修改你的表述。在此之前，你输入的内容不会被其他用户看见。
              </span>
            ),
            duration: 0,
          })
          reject({ response: { data: res.data.msg } })
        }
        resolve()
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const getCaptcha = async (data) => {
  return instance.post('/api/v1/registry/captcha/', data)
}
export const registry = async (data) => {
  return checkContent(data).then(() => instance.post('/api/v1/registry/', data))
}
export const getForgetCaptcha = async (data) => {
  return instance.post('/api/v1/forget/captcha/', data)
}
export const forgetPhone = async (data) => {
  return instance.post('/api/v1/forget/phone/', data)
}
export const forgetPassword = async (data) => {
  return instance.post('/api/v1/forget/password/', data)
}
export const login = async (data) => {
  return instance.post('/api/v1/login/', data)
}
export const requestLogout = () => {
  return instance.post('/rest-auth/logout/')
}
export const getLoginCaptcha = async (phone, opts) => {
  return instance.post('/api/v1/login/captcha/', { phone, ...opts })
}
export const getUserInfo = async (username) => {
  if (username) {
    return instance.get(`/api/v1/users/${username}/`)
  }
  return instance.get('/api/v1/users/me/')
}
export const patchUserInfo = async (formData) => {
  return checkContent(formData).then(() =>
    instance.patch('/api/v1/users/me/', formData),
  )
}
export const createTeam = async (data) => {
  return instance.post('/api/v1/team/', data)
}
export const getTeam = (params, username) => {
  if (username) {
    return instance.get(`/api/v1/users/${username}/team/`, { params })
  }
  return instance.get('/api/v1/team/', { params })
}

export const getCourse = (params) => {
  return instance.get('/api/v1/employee/courses/', { params })
}
export const getCourseBags = (params) => {
  return instance.get('/api/v1/employee/course_bags/', { params })
}

export const getPersonalInfo = (params) => {
  return instance.get('/api/v1/users/personal_data/')
}
export const changeTeam = (team_code, id, data) => {
  return instance.put(`/api/v1/team/${team_code}/students/${id}/`, data)
}
export const deleteTeam = (code, isManagerCenter = false) => {
  if(isManagerCenter){
    return instance.delete(`/api/v1/team/${code}/?from_type=管理中心`)
  }else{
    return instance.delete(`/api/v1/team/${code}/`)
  }
}

export const deleteTeamMember = (code, id) => {
  return instance.delete(`/api/v1/team/${code}/students/${id}/`)
}

export const getTeamDetail = (teamCode,from_type) => {
  if(from_type){
    return instance.get(`/api/v1/team/${teamCode}/?from_type=${from_type}`)
  }else{
    return instance.get(`/api/v1/team/${teamCode}/`)
  }
}

export const editTeamDescr = (teamcode, data) => {
  return instance.patch(`/api/v1/team/${teamcode}/`, data)
}
export const changeTeamTeacher = (teamcode, params) => {
  return instance.patch(`/api/v1/team/${teamcode}/`, params)
}
export const patchTeamDetail = (teamCode, data) => {
  console.log('patchTeamDetail:', teamCode, data)
  return instance.patch(`/api/v1/team/${teamCode}/`, data)
}
export const getMember = (teamCode, params) => {
  return instance.get(`/api/v1/team/${teamCode}/students/`, { params })
}
export const createSingleMember = (teamCode, name) => {
  return checkContent(name).then(() =>
    instance.post(`/api/v1/team/${teamCode}/students/`, { name }),
  )
}
export const createMutilMember = (teamCode, array) => {
  return checkContent(array).then(() =>
    instance.post(`/api/v1/team/${teamCode}/students/`, array),
  )
}
export const getLoginCard = (teamCode, params) => {
  return instance.get(`/api/v1/team/${teamCode}/card/`, { params })
}
export const joinTeam = (teamCode) => {
  return instance.get(`/api/v1/join/${teamCode}/`)
}
export const joinTeamWithName = (teamCode, data) => {
  return instance.post(`/api/v1/join/${teamCode}/`, data)
}
export const getRandomKey = () => {
  return instance.get('/api/v1/covers/')
}
export const createOwnPortfolio = (data) => {
  return checkContent(data).then(() => instance.post('/api/v1/studios/', data))
}
export const getOwnPortfolio = (params, username) => {
  if (username) {
    return instance.get(`/api/v1/users/${username}/studios/`, {
      params: { page: 1, page_size: 18, origin: 'longan' },
    })
  }
  return instance.get('/api/v1/profiles/studios_v2/', { params })
}
export const getOwnPortfolioDetail = (id) => {
  return instance.get(`/api/v1/studios/${id}/`)
}
export const editOwnPortfolioInfo = (id, data) => {
  return instance.patch(`/api/v1/studios/${id}/`, data)
}
export const getOwnPortfolioProjects = (id, params) => {
  return instance.get(`/api/v1/studios/${id}/projects/`, { params })
}
export const getOwnProjects = (params) => {
  return instance.get('/api/v1/profiles/projects/', { params })
}

export const getOwnPortfolioCount = (params) => {
  return instance.get('/api/v1/profiles/studio_count/', { params })
}
export const deleteOwnPortfolio = (id) => {
  return instance.delete(`/api/v1/studios/${id}/`)
}
export const getOwnPortfolioFollow = (id) => {
  return instance.get(`/api/v1/studios/${id}/follow/`)
}
export const postOwnPortfolioFollow = (id) => {
  return instance.post(`/api/v1/studios/${id}/follow/`)
}
export const deleteOwnPortfolioFollow = (id) => {
  return instance.delete(`/api/v1/studios/${id}/follow/`)
}
export const getOwnPortfolioNotifications = (id, params) => {
  return instance.get(`/api/v1/studios/${id}/notifications/`, { params })
}
export const setOnwPortfolioCover = (id, data) => {
  return instance.post(`/api/v1/studio/gallery/${id}/set/`, data)
}
export const patchOwnFolioDetail = (id, data) => {
  return instance.patch(`/api/v1/studios/${id}/`, data)
}

export const createPortfolio = (data) => {
  let formated = {
    team: data.team,
    cover: data.cover,
    studio: {
      title: data.name,
      description: data.description,
    },
    is_touch: false,
  }
  return checkContent(data).then(() =>
    instance.post('/api/v1/lms/studios/', formated),
  )
}
// 修改作品集
export const modifyPortfolio = (id, params) => {
  return instance.put(`/api/v1/lms/studios/${id}/`, params)
}
export const getPortfolios = (params) => {
  return instance.get('/api/v1/lms/studios/', { params })
}
export const getPortfolioDetail = (id,params) => {
  return instance.get(`/api/v1/lms/studios/${id}/`, { params })
}
export const getPortfolioProjects = (id, params) => {
  return instance.get(`/api/v1/lms/studios/${id}/projects/`, { params })
}
export const checkPhone = (phone) => {
  return instance.post('/api/v1/registry/phone/', { phone })
}
export const checkUsername = (username) => {
  return instance.post('/api/v1/registry/username/', { username })
}
export const checkInvitationCode = (data) => {
  return instance.get('/api/v1/invitation_check', { params: data })
}
export const bindingInvitationCode = (data) => {
  return instance.post('/api/v1/invitation_code', data)
}
export const getProjects = (params, username) => {
  if (username) {
    return instance.get(`/api/v1/users/${username}/projects/`, { params })
  }
  return instance.get('/api/v1/lms/projects/', { params })
}
export const setProjectVisiable = (id, visiable) => {
  return instance[visiable ? 'put' : 'delete'](
    `/api/v1/lms/projects/${id}/lms_public/`,
  )
}
export const deleteProject = (id) => {
  return instance.delete(`/api/v1/lms/projects/${id}/`)
}
export const deletePortfolio = (id) => {
  return instance.delete(`/api/v1/lms/studios/${id}/`)
}

export const deleteProjectFromPortfolio = (projectId, portfolioId) => {
  return instance.delete(
    `/api/v1/studios/${portfolioId}/projects/${projectId}/`,
  )
}
export const deleteAreaPortfolio = (portfolioId) => {
  return instance.delete(
    `/api/v1/org_studio/${portfolioId}/`,
  )
}
export const addProjectsToStudio = (id, proejcts) => {
  return instance.post(`/api/v1/studios/${id}/projects/`, {
    project_ids: proejcts,
  })
}
export const patchFolioDetail = (id, data) => {
  return instance.patch(`/api/v1/lms/studios/${id}/`, data)
}
export const getPortfolioNotifications = (id, params) => {
  return instance.get(`/api/v1/lms/studios/${id}/notifications/`, { params })
}
export const getAchievement = (username) => {
  return instance.get(`/api/v1/users/${username}/achievement/`)
}
export const getUnreadCount = () => {
  return instance.get('/api/v1/profiles/unread_count/?origin=longan')
}
export const getNotifications = (params) => {
  return instance.get('/api/v1/profiles/notifications/', { params })
}
export const getNotificationsCount = () => {
  return instance.get('/api/v1/profiles/notifications_count/')
}
export const markAllNotifications = () => {
  return instance.post('/api/v1/profiles/mark_all/')
}

export const setVisitHistory = (data) => {
  const campus_id = JSON.parse(localStorage.getItem('curCampus'))
  return instance.post('/api/v1/histories/', {...data, campus_id})
}
export const getVisitHistory = (params, username) => {
  if (username) {
    return instance.get(`/api/v1/users/${username}/coursehistory/`, { params })
  }
  return instance.get('/api/v1/histories/', { params })
}
export const getFavoritesList = (params, username) => {
  if (username) {
    return instance.get(`/api/v1/users/${username}/favorites/`, { params })
  }
  return instance.get(`/api/v1/profiles/favorites/`, { params })
}
export const clearFavorites = () => {
  return instance.delete(`/api/v1/profiles/favorites_clear/`)
}
export const clearFavorite = (id) => {
  return instance.delete(`/api/v1/projects/${id}/favorite/`)
}
export const getCourses = (params) => {
  return instance.get(`/api/v1/courses/?${qs.stringify(params)}`)
}
export const getCourseItem = (id) => {
  return instance.get(`/api/v1/courses/${id}/`)
}
// 教师列表
export const getTeachersList = () => {
  return instance.get(`/api/v1/employee/`)
}
// new======
// 课程预览
export const getCoursePreview = (id, random_id) => {
  return instance.get(`/api/v1/courses/${id}/course_detail/${random_id}`)
}

// 探索预览
export const getProjectPreview = (id, random_id) => {
  return instance.get(
    `/api/v1/courseprojects/${id}/courseproject_detail/${random_id}`,
  )
}
// 验证团队名称
export const fetchName = (params) => {
  return instance.get(`/api/v1/team/get_name/`, { params })
}

export const getTopCourse = (id) => {
  return instance.get(`/api/v1/lms/visit/history/top_course/`)
}
export const getHotCourses = () => {
  return instance.get('/api/v1/courses/hot/')
}
export const getProject = (params) => {
  return instance.get(`/api/v1/courseprojects/?${qs.stringify(params)}`)
}
export const getProjectItem = (id) => {
  return instance.get(`/api/v1/courseprojects/${id}/`)
}
export const feedback = (formData) => {
  return checkContent(formData).then(() =>
    instance.post('/api/v1/feedback/', formData),
  )
}
export const getCourseComment = (courseid, params) => {
  return instance.get(`/api/v1/courses/${courseid}/comments/`, { params })
}
export const submitCourseComment = (courseid, content) => {
  return instance.post(`/api/v1/courses/${courseid}/comments/`, { content })
}
export const likeCourseComment = (like, commentid) => {
  return instance[like ? 'put' : 'delete'](
    `/api/v1/coursecomments/${commentid}/like/`,
  )
}
export const getOrgTeachPlan = () => {
  return instance.get('/api/v1/teacher_plan/get_org_plan/', {})
}
export const uploadTeachPlanFile = (formData) => {
  return instance.post('/api/v1/teacher_plan/load/', formData)
}
export const getTeachPlanFiles = (params) => {
  return instance.get('/api/v1/teacher_plan/', { params })
}

export const resetTeachPlan = (params) => {
  return instance.post('/api/v1/teacher_plan/reset/', {})
}
export const getResetsStatus = () => {
  return instance.get('/api/v1/teacher_plan/reset/')
}
export const deleteTeachPlanFile = (id) => {
  return instance.delete(`/api/v1/teacher_plan/${id}/`)
}
// 禁用/删除组织管理计划
export const handleOrg_teachplan = (params) => {
  return instance.post(`/api/v1/organization_teachplan/`, params)
}
export const patchTeachPlanFile = (id, data) => {
  return instance.patch(`/api/v1/teacher_plan/${id}/`, data)
}
// 共享/取消自己上传的计划
export const shareTeachPlan = (params) => {
  return instance.post(`/api/v1/teacher_plan/share/`, params)
}
// 获取排序/更新排序
export const updateSort = (method, params) => {
  return method === 'get' ? instance.get(`/api/v1/teacher_plan/plan_sort/`) : instance.post(`/api/v1/teacher_plan/plan_sort/`, params)
}
export const toggleComment = (comment_open) => {
  return instance.patch('/api/v1/users/me/', { comment_open })
}
export const getAccountComment = (users, params) => {
  return instance.get(`/api/v1/users/${users}/comments/`, { params })
}
export const getReplyComment = (parentId, params) => {
  return instance.get(`/api/v1/usercomment/${parentId}/replies/`, { params })
}
export const addAccountComment = (users, data) => {
  return checkContent(data).then(() => {
    return instance.post(`/api/v1/users/${users}/comment/`, data)
  })
}
export const deleteAccountComment = (id) => {
  return instance.delete(`/api/v1/usercomment/${id}/`)
}
export const reportAccountComment = (id) => {
  return instance.post(`/api/v1/usercomment/${id}/report/`, {
    notes: '',
    report_category: 'other',
  })
}
export const getSepFloorComment = (id) => {
  return instance.get(`/api/v1/usercomment/${id}/comment_data/`)
}
export const getSepFloorReplies = (params) => {
  return instance.get(`/api/v1/usercomment/more_comment/`, { params })
}
// software
export const getSoftware = () => {
  return instance.get('/api/v1/teaching_software/')
}

// --- 学员账号设置 ----
export const getStudentSettings = () => {
  return instance.get('/api/v1/student_settings/')
}
export const setStudentSettings = (formData) => {
  return instance.post('/api/v1/student_settings/', formData)
}

// 获取微信二维码
export const getWXQRCode = (params) => {
  return instance.get('/api/v1/wechat_scan_login', { params })
}
// 解除绑定
export const handlerWechatUnbind = () => {
  return instance.post('/api/v1/wechat_unbind')
}

// 获取校区基础统计数据
export const getOrgBaseData = (id) => {
  return instance.get(`/api/v1/data_analysis/organization/${id}/`)
}

export const getProjectReport = (params) => {
  return instance.get('/api/v1/data_analysis/user/', { params })
}

export const getProjectAnalytics = (params) => {
  return instance.get('/api/v1/data_analysis/project/', { params })
}

export const getProjectExcellent = (params) => {
  return instance.get('/api/v1/data_analysis/project/excellent/', { params })
}

export const getProjectExtend = (params) => {
  return instance.get('/api/v1/data_analysis/extend/', { params })
}

export const getPlatformAnalytics = (params) => {
  return instance.get('/api/v1/data_analysis/platform/', { params })
}

export const getOrgActive = (id) => {
  return instance.get(`/api/v1/data_analysis/organization/${id}/active/`)
}

export const getExcel = (data) => {
  return instance({
    url: `/api/v1/data_analysis/excel/`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}
// ----团队----
export const getOrgTeamList = (id) => {
  return instance.get(`/api/v1/data_analysis/organization/${id}/teams/`)
}

export const getTeamBaseData = (id) => {
  return instance.get(`/api/v1/data_analysis/team/${id}/`)
}

export const getTeamLearnTime = (id) => {
  return instance.get(`/api/v1/data_analysis/team/${id}/course_time/`)
}

export const getTeamProjectsNum = (id) => {
  return instance.get(`/api/v1/data_analysis/team/${id}/project_nums/`)
}

export const getTeamProjectsRank = (id, params) => {
  return instance.get(`/api/v1/data_analysis/team/${id}/project_rank/`, {
    params,
  })
}
// ----员工/学员----

export const getOrgAvgTime = (id, params) => {
  console.log(params)
  return instance.get(`/api/v1/data_analysis/organization/${id}/avg_time/`, {
    params,
  })
}

export const getOrgProjectInfo = (id, params) => {
  return instance.get(
    `/api/v1/data_analysis/organization/${id}/project_info/`,
    { params },
  )
}

export const getOrgStaffTime = (id, params) => {
  return instance.get(`/api/v1/data_analysis/organization/${id}/staff_time/`, {
    params,
  })
}

export const getOrgProjectsRank = (id, params) => {
  return instance.get(
    `/api/v1/data_analysis/organization/${id}/project_rank/`,
    { params },
  )
}

export const getOrgCourseRank = (id) => {
  return instance.get(`/api/v1/data_analysis/organization/${id}/course_rank/`)
}

export const getOrgExtendRank = (id) => {
  return instance.get(`/api/v1/data_analysis/organization/${id}/extend_rank/`)
}
// ----作品----

export const getProjectsNum = (id, params) => {
  return instance.get(`/api/v1/data_analysis/organization/${id}/project_nums/`, { params })
}

export const getProjectsTopRank = (id, params) => {
  return instance.get(`/api/v1/data_analysis/organization/${id}/top/`, { params })
}

// -----------
export const getMineProgress = (params) => {
  return instance.get('/api/v1/data_analysis/progress/', { params })
}

export const getCourseViewed = (params) => {
  return instance.get('/api/v1/data_analysis/course/', { params })
}

export const getCourseViewedRank = (params) => {
  return instance.get('/api/v1/data_analysis/course/rank/', { params })
}

export const getPerfectMoment = (params) => {
  return instance.get('/api/v1/data_analysis/progress/perfect_moment/', {
    params,
  })
}

export const getExtendRank = (params) => {
  return instance.get('/api/v1/data_analysis/extend/rank/', { params })
}

export const reportTimeData = (data) => {
  return instance.post('/api/v1/data_analysis/report/', data)
}

// ----校区选择----
export const getCampus = (id) => {
  return instance.get(`/api/v2/user/${id}/campus/`)
}

export const campusSelect = (params) => {
  return instance.post(`/api/v2/user/${params.user_id}/campus_select/`,{campus_id:params.campus_id})
}


/** 消息 **/
// 查询消息是否过期
export const checkIsExpires = id => {
  return instance.get(`/api/v1/employee/${id}/check_is_expires/`)
}

// 职工回复邀请消息
export const replyInvited = (id, params) => {
  return instance.put(`/api/v1/employee/${id}/replay_invited/`, params)
}

//修改用户名
export const changeUsername = (params) => {
  return instance.put(`/api/v1/users/change_username/`, params)
}

// 区域作品集列表
export const getAreaPortfolios = (params) => {
  return instance.get(`/api/v1/org_studio/`, { params })
}
// 新建区域作品集
export const createAreaPortfolio = (params) => {
  return instance.post(`/api/v1/org_studio/`, params)
}
// 修改区域作品集
export const updateAreaPortfolio = (id, params) => {
  return instance.put(`/api/v1/org_studio/${id}/`, params)
}

// 管理者列表
export const getStudioCurator = (studio_pk,params) => {
  return instance.get(`/api/v1/studios/${studio_pk}/studiocurator/`, { params })
}

export const getAreaPortfolioDetail = (id, params) => {
  return instance.get(`/api/v1/org_studio/${id}/`, { params })
}

export const getAreaPortfolioProjects = (id,params) => {
  return instance.get(`/api/v1/org_studio/${id}/projects/`, { params })
}

export const getAllProjectIds = (studioPk) => {
  return instance.get(`/api/v1/studios/${studioPk}/projects/all_project_ids/`)
}

export const getAreaPfNotifications = (id, params) => {
  return instance.get(`/api/v1/org_studio/${id}/notifications/`, params)
}

export const addStudiocurator = (studio_pk, params) => {
  return instance.post(`/api/v1/studios/${studio_pk}/studiocurator/`, params)
}
export const delStudiocurator = (studio_pk, id) => {
  return instance.delete(`/api/v1/studios/${studio_pk}/studiocurator/${id}/`)
}
export const curatorPromotion = (studio_pk, id, params) => {
  return instance.put(`/api/v1/studios/${studio_pk}/studiocurator/${id}/`, params)
}
export const getCuratorRoles = (studio_pk) => {
  return instance.get(`/api/v1/studios/${studio_pk}/studiocurator/roles/`)
}
export const checkInvite = (studio_pk) => {
  return instance.get(`/api/v1/studios/${studio_pk}/studiocurator/curator/`)
}

export const acceptInvitation = (studio_pk, params) => {
  return instance.post(`/api/v1/studios/${studio_pk}/studiocurator/curator/`,params)
}
export const getAreaPortfolioNotifications = (id, params) => {
  return instance.get(`/api/v1/org_studio/${id}/notifications/`, {params})
}
export const getVodSign = (params) => {
  return instance.post(`/api/v1/vod_sign`, params)
}
// 教职工移出校区
export const removeToCampus = (id) => {
  return instance.delete(`/api/v1/employee/${id}/remove_to_campus/`)
}
// 教职工申请删除账号
export const removeUser = (id) => {
  return instance.delete(`/api/v1/employee/${id}/remove_user/`)
}

// 获取滑块验证码
export const getSliderCaptcha = () => {
  return instance.get('/api/v1/slider_captcha/')
};

// 验证滑块验证码
export const postSliderCaptcha = (data) => {
  return instance.post('/api/v1/slider_captcha', data)
};

export default instance
