import React, { useState, useRef, useCallback } from 'react'
import './style.scss'
import { Form, Input, Button, Select } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { getForgetCaptcha, forgetPassword, forgetPhone } from "./../../utils/request";
import { CaretDownOutlined } from '@ant-design/icons'
import { testPassword, testPhone, useCutDown } from './../../utils'
import { logout } from './../../store/actions'
import { useDispatch } from 'react-redux'
import VerifyConfirm from '../../components/verifyConfirm'

const ResetPassword = (props) => {
    // 图片验证组件
    const verifyRef = useRef();
    const dispatch = useDispatch()
    const [formData, setFormData] =  useState({ prefix: '86', phone: undefined })

    const [coldDown, coldDownTime, startCutDown, stopCutDown] = useCutDown()

    const [isredirect, redirectTime, startRedirectCutDown, stopRedirectCutDown] = useCutDown(3)  // eslint-disable-line

    const [isAuthed, setIsAuthed] = useState(false)
    const [resetSuccess, setResetSuccess] = useState(false)
    const [submitErr, setSubmitErr] = useState({}) // eslint-disable-line

    const codeRef = useRef('')

    const onValuesChange = (name, allvalues) => {
        console.log(allvalues)
        setFormData(allvalues)
    }

    const onFinish = values => {
        forgetPhone(formData).then(res => {
            codeRef.current = res.data.code
            stopCutDown()
            setIsAuthed(true)
        })
        .catch(e => {
            console.log(e)
            if (e.response.data && typeof e.response.data === 'object') {
                Object.keys(e.response.data).forEach(key => {
                    e.response.data[key] = {
                        help: e.response.data[key],
                        validateStatus: 'error'
                    }
                })
                setSubmitErr(e.response.data)
            }
        })
    };

    const resetPasswordFinish = values => {
        console.log('Received values of resetPasswordFinish: ', values);
        forgetPassword({password: values.password, code: codeRef.current}).then(res => {
            setResetSuccess(true)
            startRedirectCutDown()
            setTimeout(() => {
                dispatch(logout())
                stopRedirectCutDown()
                props.history.push('/login')
            }, 3000)
        })
    };
    // 显示/隐藏图片验证组件
    const toggleVerifyConfirm = useCallback(() => {
        verifyRef.current.toggle()
    }, [])
    const handlerGetCaptcha = async ({x, captcha_id}) => {
        if (!formData.phone) return Promise.reject()
        await getForgetCaptcha({phone: formData.phone, x, captcha_id}).then(res => {
            console.log(res.data)
            setSubmitErr({})
            startCutDown()
        })
        .catch(e => {
            console.log(e)
            if (e.response.data && typeof e.response.data === 'object') {
                Object.keys(e.response.data).forEach(key => {
                    e.response.data[key] = {
                        help: e.response.data[key],
                        validateStatus: 'error'
                    }
                })
                setSubmitErr(e.response.data)
            }
        })
    }

    return !resetSuccess ?
        <div id="resetPassword" className="container">
            <div className="title">{ isAuthed ?  '请设置新密码' : '请验证注册时的手机号'}</div>
            <div className="form">
                <Form.Provider
                    onFormFinish={(name, { values, forms }) => {
                        // console.log(values, forms)
                    }}
                >
                    <Form
                        style={{display: isAuthed ? 'none' : 'block'}}
                        name="phone"
                        className="login-form"
                        initialValues={formData}
                        onValuesChange={onValuesChange}
                        onFinish={onFinish}
                        size="large"
                    >
                        <Form.Item
                            name="phone"
                            {...submitErr.phone}
                            rules={[
                                { required: true, message: '请输入手机号' },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {

                                    if (!value || (value && testPhone(value))) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('请输入正确的手机号');
                                    }
                                })
                            ]}
                        >
                            <Input
                            addonBefore={
                                <Form.Item name="prefix" noStyle>
                                <Select defaultValue="86" suffixIcon={<CaretDownOutlined />}>
                                    <Select.Option value="86">+86</Select.Option>
                                </Select>
                                </Form.Item>
                            }
                            placeholder="手机号" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item>
                            <div className="captcha">
                                <Form.Item
                                    {...submitErr.captcha}
                                    name="captcha"
                                    style={{marginBottom: '0'}}
                                    rules={[{ required: true, message: '请输入验证码' }]}
                                >
                                    <Input placeholder="验证码"/>
                                </Form.Item>
                            </div>
                            <VerifyConfirm key={formData.phone} getCode={handlerGetCaptcha} ref={verifyRef} customEle={
                                <Button type="primary" disabled={coldDown || !testPhone(formData.phone)} onClick={toggleVerifyConfirm} className="captcha-btn">
                                    { coldDown ? `${coldDownTime}s 后可重新发送` : '获取验证码 ' }
                                </Button>
                            } />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block className="login-form-button">
                            验证手机号
                            </Button>
                        </Form.Item>
                    </Form>


                    <Form
                        style={{display: isAuthed ? 'block' : 'none'}}
                        name="password"
                        className="login-form"
                        initialValues={{ remember: true, prefix: '86' }}
                        onFinish={resetPasswordFinish}
                        size="large"
                    >
                        <Form.Item
                            name="password"
                            {...submitErr.password}
                            rules={[
                                { required: true, message: '请输入密码' },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {

                                    if (!value || (value && testPassword(value))) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('请输入 6-16 位的密码，仅支持数字、英文、下划线');
                                    }
                                })
                            ]}
                        >
                            <Input.Password placeholder="6-16 位密码，区分大小写"/>
                        </Form.Item>

                        <Form.Item
                            name="repectpassword"
                            rules={[
                                {
                                    required: true,
                                    message: '请再次输入密码',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('前后密码不一致');
                                    }
                                })
                            ]}
                        >
                            <Input.Password placeholder="确认密码"/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block className="login-form-button">
                            修改密码
                            </Button>
                        </Form.Item>
                    </Form>
                </Form.Provider>
            </div>
        </div> :
        <div id="resetSuccess">
            <div className="successImg">
                <img src="/image/success.png" width="60" height="60" alt="success"/>
            </div>
            <div className="title">
                修改密码成功
            </div>
            <div className="desc">
                {redirectTime}s 后自动跳转至登录页，或
                <Link
                    to="/login"
                    onClick={() => {
                        dispatch(logout())
                        stopRedirectCutDown()
                    }}
                >
                点击此处</Link>直接跳转</div>
        </div>
}

export default withRouter(ResetPassword)