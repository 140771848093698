import React  from 'react'
import "./style.scss"
export const TipDialog = () => {
  return <>
    <div className='tip-dialog__title'>
      英荔教学云是英荔 AI 编程教育系列产品的一部分，仅限授权用户使用，如需试用请扫码填写问卷，稍后会有专人与你联系
    </div>
    <div className='tip-dialog__qr-code'>
      <img src="/image/qr-code.png" alt=""/>
    </div>
  </>
}