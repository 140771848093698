import * as React from "react";
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import { Popconfirm, Spin } from "antd";
import "./index.scss";
import { CloseOutlined } from "@ant-design/icons";
import { getSliderCaptcha } from "../../utils/request";
const VerifyConfirm = (props, ref) => {
  let captcha_id = useRef(null);
  let left = 0;
  const { customEle, getCode } = props;
  const [registered, setRegistered] = useState(false);
  const [sidlerLeft, setSidlerLeft] = useState(0);
  const [sliderCaptcha, setSliderCaptcha] = useState({
    slider: "",
    background: "",
    captcha_id: "",
  });
  const [sliderLoading, setSliderLoading] = useState(false);
  const imagePrefix = window.location.origin.includes('localhost') ? 'https://learn.dev.longan.eliteu.xyz' : window.location.origin;
  const [open, setOpen] = useState(false);

  const changeModalVisible = (newVisible) => {
    setOpen(newVisible);
  };
  const init = () => {
    setSliderLoading(false);
    setOpen(false);
    setSidlerLeft(0);
  };
  const reloadSliderCaptcha = async () => {
    await setSliderLoading(true);
    let res = await getSliderCaptcha();
    if (res.data && res.status === 200) {
      setSliderCaptcha(res.data);
      captcha_id.current = res.data.captcha_id;
      setSliderLoading(false);
    }
  };
  const toggle = React.useCallback(() => {
    setOpen(!open);
  }, [open])
  useImperativeHandle(ref, () => ({
    // changeModalVisible 就是暴露给父组件的方法
    changeModalVisible,
    toggle,
    open,
    init,
  }));
  // eslint-disable-next-line
  const initHandle = async () => {
    await reloadSliderCaptcha();
    var draggableBlock = document.getElementById("slider-block");
    var isDragging = false;
    var offsetX;
    if (draggableBlock && !registered) {
      const startFn = (clientX) => {
        isDragging = true;
        offsetX = clientX - draggableBlock.offsetLeft;
      };
      draggableBlock.addEventListener("mousedown", function (e) {
        startFn(e.clientX);
      });
      draggableBlock.addEventListener("touchstart", function (e) {
        startFn(e.touches[0].clientX);
      });
      const moveFn = (leftVal) => {
        if (isDragging) {
          left = leftVal;
          if (left > 200) {
            left = 200;
          }
          if (left < 0) {
            left = 0;
          }
          setSidlerLeft(left);
        }
      };
      // 鼠标移动时触发
      document.addEventListener("mousemove", function (e) {
        let left = e.clientX - offsetX;
        moveFn(left);
      });

      document.addEventListener("touchmove", function (e) {
        let left = e.touches[0].clientX - offsetX;
        moveFn(left);
      });
      const endFn = async () => {
        console.log("mouseup--");
        isDragging = false;
        if (left !== 0) {
          setSliderLoading(true);
          if (captcha_id.current) {
            let x = parseFloat((left / 250).toFixed(2));
            getCode({
              captcha_id: captcha_id.current,
              x,
            })
              .then((res) => {
                console.log("请求成功!!!!!!", res);
                setSliderLoading(false);
                setOpen(false);
                setSidlerLeft(0);
                left = 0;
                captcha_id.current = null;
              })
              .catch((err) => {
                console.log("请求错误", err);

                left = 0;
                captcha_id.current = null;
                setSidlerLeft(0);
                reloadSliderCaptcha();
              });
          }
        }
      };
      // 鼠标释放时触发
      document.addEventListener("mouseup", endFn);
      document.addEventListener("touchend", endFn);
      setRegistered(true);
    }
  };
  useEffect(() => {
    if (open) {
      initHandle();
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Popconfirm
      visible={open}
      placement="topRight"
      icon={null}
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
      cancelButtonProps={{
         style: {
          display: "none",
        },
      }}
      overlayClassName="verify-confirm"
      title={
        <Spin size="small" spinning={sliderLoading}>
          <div className="verification">
            <p className="title">
              <span>拖动下方滑动完成拼图</span>
              <CloseOutlined
                style={{ fontSize: "16px", color: "#ccc" }}
                onClick={() => setOpen(false)}
              />
            </p>
            <div className="image">
              <img
                src={imagePrefix + '/' + sliderCaptcha.slider}
                alt=""
                className="image-block"
                style={{ left: `${sidlerLeft}px` }}
                onDragStart={(e) => e.preventDefault()}
                onDragOver={(e) => e.preventDefault()}
              />
              <img
                src={imagePrefix + '/' + sliderCaptcha.background}
                onDragStart={(e) => e.preventDefault()}
                onDragOver={(e) => e.preventDefault()}
                alt=""
                className="image-bg"
              />
            </div>
            <div className="slider">
              <div
                className="block"
                id="slider-block"
                onDragStart={(e) => e.preventDefault()}
                onDragOver={(e) => e.preventDefault()}
                style={{ left: `${sidlerLeft}px` }}
              >
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <div className="range"></div>
            </div>
          </div>
        </Spin>
      }
      cancelText={null}
      okText={null}
    >
      {customEle}
    </Popconfirm>
  );
};

export default forwardRef(VerifyConfirm);
